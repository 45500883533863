import { OAUTH_PATH, oidcUserManagerSigninRedirectCallback } from "./lib/oidcUserManager";
import "./lib/polyfills";
import { applyShareableURL } from "./lib/urlTools";

console.debug(
  `%cMetroscope ${__PACKAGE_VERSION__}\n%c${process.env["NODE_ENV"]} build %c${__GIT_TAG__} %c#${__GIT_HASH__} (${__GIT_DATE__})`,
  "color: purple;",
  "color: grey;",
  "color: black;",
  "color: grey;",
);

const urlWithoutShareable = applyShareableURL();
if (urlWithoutShareable !== null) {
  window.history.replaceState(null, "", urlWithoutShareable);
}

if (window.location.pathname === OAUTH_PATH) void oidcUserManagerSigninRedirectCallback();
else void import("./react-app");
