import type { UserManagerSettings } from "oidc-client-ts";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { MTS_ENV } from "./setupEnv";
import { decodeToken } from "./decodeToken";
import { hrefWithEditedSearchParams } from "./urlTools";

export const OAUTH_PATH = "/oauth/signin";
export const OAUTH_SIGNED_IN = "oauth-signed-in";
export const OIDC_USER_STORE_KEY = `oidc.user:${MTS_ENV.OIDC_AUTHORITY}:${MTS_ENV.OIDC_CLIENT_ID}`;

const { protocol, host } = window.location;

const authenticationConfig: UserManagerSettings = {
  authority: MTS_ENV.OIDC_AUTHORITY,
  client_id: MTS_ENV.OIDC_CLIENT_ID,
  client_secret: MTS_ENV.OIDC_CLIENT_SECRET,
  redirect_uri: `${protocol}//${host}${OAUTH_PATH}`,
  post_logout_redirect_uri: `${protocol}//${host}/`,
  response_type: "code",
  scope: "openid profile email",
  accessTokenExpiringNotificationTimeInSeconds: 15,
  // silent renew will get a new access_token via an iframe
  automaticSilentRenew: true,
  // will revoke (reference) access tokens at logout time
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

// we use pkce flow (response_type: "code")
// https://developer.okta.com/blog/2019/08/22/okta-authjs-pkce
// https://www.scottbrady91.com/Angular/Migrating-oidc-client-js-to-use-the-OpenID-Connect-Authorization-Code-Flow-and-PKCE
const oidcUserManager = new UserManager(authenticationConfig);

oidcUserManager.events.addUserSessionChanged((...params: unknown[]) =>
  console.debug("[oauth] user session changed", { params }),
);
oidcUserManager.events.addSilentRenewError((...params: unknown[]) =>
  console.debug("[oauth] silent renew error", { params }),
);
oidcUserManager.events.addAccessTokenExpiring((...params: unknown[]) =>
  console.debug("[oauth] access token expiring", { params }),
);
oidcUserManager.events.addAccessTokenExpired((...params: unknown[]) =>
  console.debug("[oauth] access token expired", { params }),
);

export async function oidcUserManagerSigninRedirectCallback() {
  try {
    const { access_token, refresh_token, id_token, state, ...otherParams } =
      await oidcUserManager.signinRedirectCallback();

    console.debug("[oauth] Keycloak signin success", {
      oidc_access_token: decodeToken(access_token),
      oidc_refresh_token: refresh_token && decodeToken(refresh_token),
      oidc_id_token: id_token && decodeToken(id_token),
      state,
      otherParams,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.location = state as any;
  } catch (error) {
    console.debug("[oauth] Keycloak signin failure", { error });
    window.location.pathname = "/";
  }
}

export async function revokeTokens() {
  await oidcUserManager.revokeTokens();
}

export function oidcUserManagerSigninRedirect() {
  return oidcUserManager.signinRedirect({
    state: hrefWithEditedSearchParams(sp => {
      sp.set(OAUTH_SIGNED_IN, "true");
    }, false),
  });
}

export function oidcUserManagerSignoutRedirect() {
  void oidcUserManager.getUser().then(user => {
    if (!user) return console.debug("[oauth] no user to sign out");

    void oidcUserManager.signoutRedirect({
      id_token_hint: user.id_token,
      post_logout_redirect_uri: window.location.href,
    });
  });
}

export function oidcUserManagerGetLocalUser() {
  const authentication = window.localStorage.getItem(OIDC_USER_STORE_KEY);
  if (authentication === null)
    throw new Error("invalid authentication token, please refresh the page");
  return User.fromStorageString(authentication);
}

export default oidcUserManager;
