import jwtDecode from "jwt-decode";

export function decodeToken(jwtToken: string) {
  const value: Record<string, unknown> = jwtDecode(jwtToken);
  const expirationNumber = "exp" in value ? value["exp"] : null;
  const expirationDate =
    typeof expirationNumber === "number" ? new Date(expirationNumber * 1000) : null;
  const expiration = expirationDate ? expirationDate.toJSON() : null;
  return { ...value, expiration };
}
